<template>
  <div class="w-full relative">
    <TotalComplaints :total="count" title="Total" body="alertas registradas" />
    <div
    @key-up.enter="search"
      class="mb-6 rounded-xl shadow-xl mx-4 bg-white px-6 pt-4 pb-6 flex gap-5"
    >
      <div class="w-full m-0" >
        <div class="flex items-center gap-4 mt-0">
          <a-icon type="filter" />
          <h2 class="text-left text-lg text-gray-600">
            <b>Filtrar notificaciones</b>
          </h2>
        </div>
        <div class="relative flex sm:ml-0 lg:ml-42 xl:ml-80 gap-6">
          <BaseSelect
            ref="alertType"
            id="alertType"
            label="Tipo de alerta"
            :selectOptions="alertTypes"
            v-model="alertType"
            :loading="loading"
          />
          <BaseRangeDate
            ref="Fecha de creacion"
            label="Fecha de creación"
            @notify-dates="setRangeDate($event)"
          />

          <a-button
            type="primary"
            shape="round"
            class="mt-auto h-8"
            @click.native="search"
          >
            <a-icon type="search" :style="{ fontSize: '19px' }"></a-icon>
          </a-button>
        </div>
      </div>
    </div>
    <div
      class="mx-4 px-6 py-6 md:px-10 md:py-10 bg-white rounded-2xl shadow-lg min-h"
    >
      <AlertListBase
        :data="data"
        :loading="loading"
        title="Listado de notificaciones"
        :subtitle="subtitle"
        :filter_date="false"
        :loadMore="loadMore"
        :alert_title="true"
        @viewed="viewed($event)"
        @loadMore="onLoadMore"
      />
    </div>
  </div>
</template>
<script>
import AlertListBase from "./AlertListBase.vue";
import moment from "moment";
import capitalize from "@/utils/checkForms.js";
import TotalComplaints from "../Complaints/TotalComplaints.vue";
export default {
  components: { AlertListBase, TotalComplaints },
  data() {
    return {
      loading: true,
      loadMore: true,
      data: [],

      start_date: "",
      end_date: "",

      alertType: "",
      alertTypes: [],
      page: 1,
      pageSize: 10,
      subtitle: "",
      count: 0,
    };
  },
  created() {
    this.getAlertTypes();
    let page = this.$route.query?.page ? this.$route.query?.page : 1;
    this.start_date = this.$route.query?.start_date
      ? this.$route.query?.start_date
      : moment().set(new Date()).subtract(15, "d").format("YYYY-MM-DD");
    this.end_date = this.$route.query?.end_date
      ? this.$route.query?.end_date
      : moment().set(new Date()).format("YYYY-MM-DD");
    this.alertType = this.$route.query?.alertType
      ? this.$route.query?.alertType
      : "";

    this.getData(page * this.pageSize);
  },
  methods: {
    async getData(pageSize = this.pageSize) {
      this.loading = true;
      //request data
      let query = `?page_size=${pageSize}&page=${this.page}&start_date=${this.start_date}&end_date=${this.end_date}`;

      if (this.alertType) {
        query += `&alert_configuration__alert_type__id=${this.alertType}`;
      }
      let { data, error } = await this.$api.getAlerts(query);

      if (error) {
        this.loading = false;
        this.data = [];
        this.showToast("error", "No hay datos para mostrar.");
        this.subtitle = "";
      }
      if (data) {
        this.loading = false;
        this.data = data.results.map((item) => {
          return {
            ...item,
            title: capitalize(item.configuration_alert_name),
            description: item.detail_alert,
            viewed: item.viewed,
          };
        });

        this.count = data.count;
        this.subtitle = `Últimas alertas`;
        this.loadMore = data.next !== null;
      }
    },
    async getAlertTypes() {
      this.loading = true;
      let { data } = await this.$api.getAlertTypes("");
      if (data) {
        this.loading = false;
        this.alertTypes = data.results.map((alertType) => {
          return { ...alertType };
        });
      }
    },
    setRangeDate(eventValue) {
      this.start_date = eventValue[0];
      this.end_date = eventValue[1];
      this.getData();
    },
    async onLoadMore() {
      this.page++;
      this.loading = true;
      //request data
      let query = `?page_size=${this.pageSize}&page=${this.page}&start_date=${this.start_date}&end_date=${this.end_date}&alert_configuration_alert_type_id=${this.alertType}`;
      let { data, error } = await this.$api.getAlerts(query);

      if (error) {
        this.loading = false;
        if (error.status_code == 404 && error.detail == "Invalid page.") {
          this.showToast("error", "No hay más notificationes.");
          this.loadMore = false;
        } else this.showToast("error", "No hay datos para mostrar.");
      }
      if (data) {
        this.loading = false;
        let alerts = data.results.map((item) => {
          return {
            ...item,
            title: capitalize(item.configuration_alert_name),
            description: item.detail_alert,
            viewed: item.viewed,
          };
        });
        this.data = this.data.concat(alerts);
        this.count = data.count;
        this.loadMore = this.count !== this.data.length;
      }
    },
    async viewed(item) {
      this.data.forEach((alert) => {
        if (alert.id == item) alert.viewed = true;
      });
      let { data, error } = await this.$api.setReadAlert({ alert: item });

      if (error) {
        this.data.forEach((alert) => {
          if (alert.id == item) alert.viewed = false;
        });
      } else if (data) {
        this.$store.dispatch("session/getNotifications");
      }
    },
    search() {
      this.page = 1;
      this.getData();
    },
    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => this.$notification.close(key),
                  },
                },
                "Entendido"
              );
            }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
  },
  watch: {
    "$store.state.session.notifications.count": {
      handler(newVal, oldVal) {
        this.getData();
        
      },
      deep: true,
    },
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        let query = {
          page: this.page,
          start_date: this.start_date,
          end_date: this.end_date,
          alertType: this.alertType,
        };
        this.$router.replace({ query }).catch(() => {
          return;
        });
      }
    },
  },
};
</script>
<style scoped>
.viewed {
  left: 83%;
}
.min-h {
  min-height: 25em;
}
</style>
