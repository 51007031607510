<template>
  <Alerts />
</template>

<script>
import Alerts from "../../../components/Dashboard/Alerts/Alerts.vue";

export default {
  components: { Alerts },
};
</script>
